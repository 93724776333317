import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./Components/Pages/HomePage";
import HomeData from "./Data/HomePage.json";

const Header = lazy(() => import("./Components/Layout/Header"));

function App() {
	const data = HomeData;

     //NOTE: making auth = true above will release the site - You MUST have with // and one without.

     //let auth = true;                              // <=== this opens the site - remove the first two "//""
     let auth = localStorage.getItem('authorized');  // <==== this locks the site - remove the first two "//"

     let passWord = () => {

          if (auth){
               return;
          }

          let testV = 1;
          let pass1 = prompt("Please Enter Your Password", " ");
          while (testV < 3) {
               // if (!pass1) history.go(-1);
               if (pass1 && pass1.toLowerCase() === "ahah2021") {
                    // alert("You Got it Right!");
                    // window.open("protectpage.html");
                    localStorage.setItem('authorized', true);
                    auth = localStorage.getItem('authorized');
                    break;
               }
               testV += 1;
               pass1 = prompt(
                    "Access Denied - Password Incorrect, Please Try Again.",
                    ""
               );
          }
          if ((testV === 3)) { 
               window.location.replace("http://google.com");
          };
          return " ";
     }
     passWord();

	return (
		<div>
			<Suspense fallback={<p></p>}>
                    {auth ? <Header data={data} /> : ""}
			</Suspense>

			<Router>
				<Suspense fallback={<p className="m-auto">loading</p>}>
					<Switch>
						<Route path="/">
                                   {auth ? <HomePage data={data} /> : ""}
						</Route>
					</Switch>
				</Suspense>
			</Router>
		</div>
	);
}

export default App;
