import React, { useState, lazy, Suspense } from "react";

const RightImageTemplate = lazy(() => import("../ProgramTemplates/RightImage"));

function HomePage({ data }) {
	const HomeTemplates = {
		RightImageTemplate,
	};

	const [selectedProgram, setSelectedProgram] = useState("none");

	let HandleProgramChange = (e) => {

		// console.log("HandleProgramChange", selectedProgram, e.target.value, Number.isNaN(selectedProgram));

		if (Number.isNaN(e.target.value)) {
			setSelectedProgram("none");
               return;
		}

          setSelectedProgram(Number(e.target.value));

	};

	return (
		<div
			id="top"
			className="xxsm:mt-10vh-safe lg:mt-10vh-safe hd:mt-5vh-safe w-screen"
		>
			<div className="xxsm:px-4 lg:px-12 pt-4 pb-4 hd:max-w-80vw mx-auto">
				<p className="text-center">
					We are currently experiencing technical difficulties with our standard
					donation process and are working with our service provider to get this
					resolved as soon as possible. Donations to All Hands and Hearts can
					still be made using the menu below to perform a secure transaction
					through PayPal.
				</p>
			</div>

			<div className="xxsm:px-4 xxsm:py-2 lg:px-12 pt-8 pb-4 text-center">
				<select onChange={HandleProgramChange}>
					<option value="none">Please Select a Program</option>
					{data.map(({select_title }, index) => {
						return (
							<option value={index} key={index}>
								{select_title}
							</option>
						);
					})}
				</select>
			</div>

			<Suspense fallback={<p className="m-auto">loading</p>}>
				{data.map((program, index) => {
					const ProgramSlider = HomeTemplates[program.template];
					if (!ProgramSlider) {
						return (
							<RightImageTemplate
								program={program}
								key={index}
								index={index}
								selectedProgram={selectedProgram}
							/>
						);
					} else {
						return <ProgramSlider program={program} key={index} />;
					}
				})}
				{selectedProgram === "none" || Number.isNaN(selectedProgram) ? (
					<div
						className="min-h-70vh w-screen bg-cover bg-center"
						style={{ backgroundImage: "url(/img/default-image.jpg)" }}
					></div>
				) : (
					""
				)}
			</Suspense>
		</div>
	);
}

export default HomePage;
